import ReactSlidingPane from "react-sliding-pane";
import ContentContainer from "../../components/content/ContentContainer";
import "react-sliding-pane/dist/react-sliding-pane.css";

import { useCallback, useContext, useEffect, useRef, useState } from "react";
import compStyles from "./buyerregistration.module.css";
import { ColumnDirective, ColumnsDirective, DataStateChangeEventArgs, GridComponent, row } from "@syncfusion/ej2-react-grids";
import { Inject, Page, Sort } from "@syncfusion/ej2-react-grids";
import dayjs from "dayjs";
import { getValue } from "@syncfusion/ej2-base";
import { BeatLoader } from "react-spinners";
import { debounce, isArray } from "lodash";
import { PlusCircleIcon, ChatIcon, XCircleIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import { TableLoader } from "../../components/widgets/SkeletonScreens";
import { getAllTickets, ticketTypeahead } from "../../adapters";
import RegisterBuyerForm from "./RegisterBuyerForm";
import ActionsDropdown from "./components/ActionsDropdown";
import Modal from "react-modal";
import ReactModal from "react-modal";
import CancelRegistrationModal from "./components/CancelRegistrationModal";
import { format } from "path";
import { dateFormatter, formatPhoneNumber, isValidPhoneNumber } from "./utils";
import { ThemeContext } from "../../components/settings/theme/ThemeContext";
import ReactTooltip from "react-tooltip";

export interface CoBuyer {
	name: Buyer["name"];
	phoneNumber: Buyer["phoneNumber"];
	email: Buyer["email"];
	otherAgentsExclusive?: Buyer["otherAgentsExclusive"];
	otherAgentsNonExclusive?: Buyer["otherAgentsNonExclusive"];
}

export interface Buyer {
	active: string;
	name: string;
	phoneNumber: string;
	email: string;
	activeAgencyExecuted: string;
	startDate: string;
	endDate: string;
	agreementType: string;
	otherAgentsExclusive?: number;
	otherAgentsNonExclusive?: number;
	registeredDate?: string;
	cobuyers?: CoBuyer[];
}

const mockData: Buyer[] = [
	{
		active: "Yes",
		name: "Peter Parker",
		phoneNumber: "123-456-7890",
		email: "peter@dailybugle.com",
		activeAgencyExecuted: "yes",
		startDate: "09/10/2024",
		endDate: "09/25/2024",
		agreementType: "exclusive",
		otherAgentsExclusive: 2,
		otherAgentsNonExclusive: 1,
		registeredDate: "01/01/2023",
		cobuyers: [
			{
				name: "Aunt May",
				phoneNumber: "111-222-3333",
				email: "auntmay@dailybugle.com",
				otherAgentsExclusive: 1,
				otherAgentsNonExclusive: 0,
			},
			{
				name: "Mary Jane Watson",
				phoneNumber: "555-222-1131",
				email: "mjwatson@broadway.com",
				otherAgentsExclusive: 0,
				otherAgentsNonExclusive: 1,
			},
		],
	},
	{
		active: "No",
		name: "Tony Stark",
		phoneNumber: "234-567-8901",
		email: "tony@starkindustries.com",
		activeAgencyExecuted: "yes",
		startDate: "08/10/2024",
		endDate: "10/30/2024",
		agreementType: "non-exclusive",
		otherAgentsExclusive: 1,
		otherAgentsNonExclusive: 3,
		registeredDate: "02/01/2023",
		cobuyers: [
			{
				name: "Happy Hogan",
				phoneNumber: "222-333-4444",
				email: "happy@starkindustries.com",
				otherAgentsExclusive: 0,
				otherAgentsNonExclusive: 1,
			},
		],
	},
	{
		active: "Yes",
		name: "Steve Rogers",
		phoneNumber: "345-678-9012",
		email: "steve@avengers.com",
		activeAgencyExecuted: "yes",
		startDate: "03/01/2023",
		endDate: "02/29/2024",
		agreementType: "exclusive",
		otherAgentsExclusive: 0,
		otherAgentsNonExclusive: 0,
		registeredDate: "03/01/2023",
		cobuyers: [
			{
				name: "Bucky Barnes",
				phoneNumber: "333-444-5555",
				email: "bucky@avengers.com",
				otherAgentsExclusive: 0,
				otherAgentsNonExclusive: 0,
			},
		],
	},
	{
		active: "No",
		name: "Bruce Banner",
		phoneNumber: "456-789-0123",
		email: "bruce@avengers.com",
		activeAgencyExecuted: "yes",
		startDate: "04/01/2023",
		endDate: "12/31/2024",
		agreementType: "exclusive",
		otherAgentsExclusive: 1,
		otherAgentsNonExclusive: 2,
		registeredDate: "04/01/2023",
		cobuyers: [],
	},
	{
		active: "Yes",
		name: "Natasha Romanoff",
		phoneNumber: "567-890-1234",
		email: "natasha@shield.com",
		activeAgencyExecuted: "yes",
		startDate: "09/10/2024",
		endDate: "09/29/2024",
		agreementType: "non-exclusive",
		otherAgentsExclusive: 0,
		otherAgentsNonExclusive: 1,
		registeredDate: "05/01/2023",
		cobuyers: [
			{
				name: "Yelena Belova",
				phoneNumber: "555-666-7777",
				email: "yelena@shield.com",
				otherAgentsExclusive: 0,
				otherAgentsNonExclusive: 1,
			},
		],
	},
	{
		active: "No",
		name: "Thor Odinson",
		phoneNumber: "678-901-2345",
		email: "thor@asgard.com",
		activeAgencyExecuted: "no",
		startDate: "06/01/2023",
		endDate: "05/31/2024",
		agreementType: "exclusive",
		otherAgentsExclusive: 2,
		otherAgentsNonExclusive: 2,
		registeredDate: "06/01/2023",
		cobuyers: [
			{
				name: "Loki",
				phoneNumber: "666-777-8888",
				email: "loki@asgard.com",
				otherAgentsExclusive: 1,
				otherAgentsNonExclusive: 1,
			},
		],
	},
];

const BuyerRegistrationHome = (props: any) => {
	const styles = { ...compStyles };
	const gridControl = useRef<any>("");

	const [firstLoad, setFirstLoad] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [showRegisterPopup, setShowRegisterPopup] = useState(false);
	const [showSidePanel, setShowSidePanel] = useState(false);

	const [activePage, setActivePage] = useState(0);
	const [data, setData] = useState<{ result: any; count: string }>();
	const [sortParams, setSortParams] = useState<any[]>([]);
	const [selectedBuyer, setSelectedBuyer] = useState<any>();
	const [buyerToCancel, setBuyerToCancel] = useState<Buyer | null>(null);
	const [searchBuyerData, setSearchBuyerData] = useState<{ exclusive: number; nonExclusive: number }>({ exclusive: 0, nonExclusive: 0 });
	const [searchPhoneNumber, setSearchPhoneNumber] = useState("");
	const { theme } = useContext(ThemeContext);

	useEffect(() => {
		fetchData();
	}, [activePage, sortParams]);

	const fetchData = () => {
		if (gridControl.current) {
			gridControl.current.showSpinner();
		}
		getAllTickets({
			activePage: activePage,
			pageSize: 10,
			orderBy: sortParams,
		}).then((response: any) => {
			if (gridControl.current) {
				gridControl.current.hideSpinner();
			}
			setData({
				result: mockData,
				count: mockData.length.toString(),
			});
			setFirstLoad(false);
		});
	};

	const handleStateChange = (state: DataStateChangeEventArgs) => {
		if (state.action) {
			if (state.action.requestType === "sorting") {
				if ("sorted" in state) {
					let sortOrder: any[] = [];
					state.sorted?.forEach((sort) => {
						sortOrder.push({
							field: sort.name,
							direction: sort.direction === "ascending" ? "Asc" : "Desc",
						});
					});
					setSortParams(sortOrder);
				} else {
					setSortParams([]);
				}
			} else if (state.action.requestType === "paging") {
				if ((state.action as any).currentPage - 1 !== activePage) {
					setActivePage((state.action as any).currentPage - 1);
				}
			}
		} else {
			state.skip = 1;
		}
	};

	const handleCancel = (buyer: Buyer) => {
		setBuyerToCancel(buyer);
		setIsModalOpen(true);
		console.log("Cancel action triggered");
	};

	const handleEdit = (e: Buyer) => {
		setSelectedBuyer(e);
		setShowSidePanel(true);
	};

	const handleSearch = (searchTerm: string) => {
		debounceSearch.cancel();
		ticketTypeahead(searchTerm).then((response: any) => {
			// setActivePage(1);
			// setData({
			// 	result: response.data.results,
			// 	count: response.data.totalResults,
			// });
			setShowRegisterPopup(true);
			setSearchBuyerData({ exclusive: 2, nonExclusive: 1 });
			setIsLoading(false);
		});
	};
	const debounceSearch = debounce(handleSearch, 500);
	const debouncedHandleSearch = useCallback(debounceSearch, []);

	const nameTemplate = (props: any) => {
		let field = props.column.field; // this will be the actual column
		let tag = props[field] ?? "No Name";
		if (isArray(tag)) {
			tag = tag.join(", ");
		}
		return <div className="cursor-pointer">{tag}</div>;
	};

	const headerTemplate = (props: any) => {
		return <div title={props.headerText}>{props.headerText}</div>;
	};

	return firstLoad ? (
		<TableLoader />
	) : (
		<span className="buyer-registration">
			{buyerToCancel && <CancelRegistrationModal fetchData={fetchData} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} buyerToCancel={buyerToCancel} />}
			<ContentContainer title={"Buyer Registration"} actions={null}>
				<div className="w-full flex flex-col sm:flex-row justify-between gap-4 items-center">
					<div className="flex-1 sm:flex-none w-full sm:w-auto">
						<button
							onClick={() => {
								setSelectedBuyer(undefined);
								setSearchPhoneNumber("");
								setShowSidePanel(true);
							}}
							type="button"
							className="action-button bg-green-600 w-full sm:w-auto"
						>
							<PlusCircleIcon className="h-4 w-4 mr-1" /> Register New Buyer
						</button>
					</div>
					<div className="flex-1 sm:flex-none w-full sm:w-1/2 lg:w-1/3 flex items-center">
						<div className="relative flex-1">
							{isLoading && (
								<span style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}>
									<BeatLoader size={5} color={theme === "light" ? "black" : "white"} />
								</span>
							)}
							<input
								type="text"
								className="w-full placeholder-secondary focus:ring-none focus:border-divider border-default rounded-md bg-secondary text-secondary"
								placeholder={"Enter a Phone Number to Search Buyer Registry"}
								onChange={(e) => {
									const formatted = formatPhoneNumber(e.target.value);
									setSearchPhoneNumber(formatted);
									console.log("formatted", formatted);
									console.log("valid", isValidPhoneNumber(formatted));
									if (isValidPhoneNumber(formatted)) {
										setIsLoading(true);
										debouncedHandleSearch(formatted);
									} else {
										setShowRegisterPopup(false);
									}
								}}
								onBlur={() => {
									setShowRegisterPopup(false);
								}}
								onFocus={() => {
									if (isValidPhoneNumber(searchPhoneNumber)) {
										setShowRegisterPopup(true);
									}
								}}
								value={searchPhoneNumber}
							></input>
							{showRegisterPopup && (
								<div className={`${styles.search_results} shadow-lg ${theme === "light" ? "bg-white" : "theme-bg"}`}>
									<div>
										<span style={{ color: "red" }}>{searchBuyerData.exclusive} Exclusive Agreements</span>
									</div>
									<div>
										<span>{searchBuyerData.nonExclusive} Non-Exclusive Agreements</span>
									</div>
								</div>
							)}
						</div>
						<div className="ml-2">
							<button
								onClick={() => {
									setSelectedBuyer(undefined);
									setShowSidePanel(true);
								}}
								type="button"
								className="action-button bg-green-600 w-full sm:w-auto"
								style={{
									height: "39px",
									backgroundColor: !/^\d{3}-\d{3}-\d{4}$/.test(searchPhoneNumber) ? "gray" : "#059669",
									cursor: !/^\d{3}-\d{3}-\d{4}$/.test(searchPhoneNumber) ? "not-allowed" : "pointer",
								}}
								disabled={!/^\d{3}-\d{3}-\d{4}$/.test(searchPhoneNumber)}
							>
								<PlusCircleIcon className="h-4 w-4" />
								{!/^\d{3}-\d{3}-\d{4}$/.test(searchPhoneNumber)}
							</button>
						</div>
					</div>
				</div>
				<div className="syncfusion-grid w-full mt-5 buyer-registration-data-grid">
					<GridComponent
						ref={gridControl}
						dataStateChange={handleStateChange}
						dataSource={data}
						rowSelecting={(row: any) => {
							// console.log(row);
							// setSelectedBuyer(row.data);
							// setShowSidePanel(true);
						}}
						allowPaging={true}
						pageSettings={{ pageCount: 10, pageSize: 10 }}
						allowSorting={true}
						resizeSettings={{ mode: "Auto" }}
						dataBound={() => {
							gridControl.current.autoFitColumns([]);
						}}
					>
						<ColumnsDirective>
							<ColumnDirective field="name" headerText="Buyer" template={nameTemplate} isPrimaryKey={true} width="150" headerTemplate={headerTemplate} />
							<ColumnDirective
								field="otherAgentsExclusive"
								headerText="Other Agents Exclusive"
								width="90"
								headerTemplate={(props: any) => (
									<span style={{ textTransform: "none" }}>
										EXCLUSIVE
										<span data-tip="Exclusive agreements between buyer and other agents" data-for="exclusive-tooltip">
											<ExclamationCircleIcon style={{ display: "inline-block", textTransform: "none", position: "relative", left: "2px", top: "-1px" }} width={14} />
										</span>
										<ReactTooltip id="exclusive-tooltip" place="top" html={true} />
									</span>
								)}
								template={(props: any) => <div style={{ color: props.otherAgentsExclusive > 0 ? "red" : "inherit" }}>{props.otherAgentsExclusive}</div>}
							/>
							<ColumnDirective
								field="otherAgentsNonExclusive"
								headerText="Other Agents Non-Exclusive"
								width="90"
								headerTemplate={(props: any) => (
									<span style={{ textTransform: "none" }}>
										NON-EXCLUSIVE
										<span data-tip="Non-exclusive agreements between buyer and other agents" data-for="non-exclusive-tooltip">
											<ExclamationCircleIcon style={{ display: "inline-block", position: "relative", left: "2px", top: "-1px" }} width={14} />
										</span>
										<ReactTooltip id="non-exclusive-tooltip" place="top" html={true} />
									</span>
								)}
							/>
							<ColumnDirective
								field="coBuyerName"
								headerText="Co-Buyer(s)"
								width="150"
								headerTemplate={headerTemplate}
								template={(props: any) => (
									<ul className="list-disc list-inside">
										{props.cobuyers && props.cobuyers.length > 0 ? (
											props.cobuyers.map((coBuyer: CoBuyer, index: number) => (
												<li key={index} className="text-sm">
													{coBuyer.name}
												</li>
											))
										) : (
											<li className="text-sm text-gray-500">None</li>
										)}
									</ul>
								)}
							/>
							<ColumnDirective field="phoneNumber" headerText="Phone Number" width="100" />
							<ColumnDirective field="registeredDate" headerText="Registered Date" valueAccessor={dateFormatter} width="100" headerTemplate={headerTemplate} />
							<ColumnDirective field="agreementType" headerText="Agreement Type" width="100" headerTemplate={headerTemplate} template={(props: { agreementType: string }) => <div>{props.agreementType.charAt(0).toUpperCase() + props.agreementType.slice(1)}</div>} />
							<ColumnDirective
								field="endDate"
								headerText="Agreement Expiration"
								valueAccessor={dateFormatter}
								width="100"
								headerTemplate={headerTemplate}
								template={(props: any) => {
									const daysUntilExpiration = dayjs(props.endDate).diff(dayjs(), "day");
									const isAboutToExpire = daysUntilExpiration <= 10 && daysUntilExpiration >= 0;
									return isAboutToExpire ? (
										<>
											<div data-for={`${props.endDate}`} data-tip={`This agreement expires in ${daysUntilExpiration} ${daysUntilExpiration === 1 ? "day" : "days"}`} style={{ color: isAboutToExpire ? "orange" : "inherit" }}>
												{props.endDate}
											</div>
											<ReactTooltip id={`${props.endDate}`} place="top" html={true} />
										</>
									) : (
										<>
											<div style={{ color: isAboutToExpire ? "orange" : "inherit" }}>{props.endDate}</div>
										</>
									);
								}}
							/>

							<ColumnDirective
								headerText="Actions"
								width="53"
								template={(props: any) => (
									<div className="float-right">
										<ActionsDropdown
											rowData={props}
											actions={[
												{ text: "Edit", callback: handleEdit },
												{ text: "Cancel", callback: handleCancel },
											]}
										/>
									</div>
								)}
							/>
						</ColumnsDirective>
						<Inject services={[Page, Sort]} />
					</GridComponent>
				</div>
				<ReactSlidingPane
					isOpen={showSidePanel}
					width={window.innerWidth <= 768 ? "100%" : "35%"}
					title={selectedBuyer ? "Update Buyer Information" : "Register New Buyer"}
					onRequestClose={() => {
						setSearchPhoneNumber("");
						setShowSidePanel(false);
					}}
					closeIcon={
						window.innerWidth <= 768 ? (
							<div>
								<XCircleIcon style={{ width: "28px" }} />
							</div>
						) : null
					}
				>
					<RegisterBuyerForm setShowSidePanel={setShowSidePanel} buyer={selectedBuyer} searchPhoneNumber={searchPhoneNumber} />
				</ReactSlidingPane>
			</ContentContainer>
		</span>
	);
};

export default BuyerRegistrationHome;
