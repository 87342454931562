import React, { useContext, useEffect, useState } from "react";
import { FormikProps, getIn } from "formik";
import BeatLoader from "react-spinners/BeatLoader";
import FormikError from "./components/FormikError";
import { disabledBgColor, formatPhoneNumber, isValidPhoneNumber } from "./utils";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { ThemeContext } from "../../components/settings/theme/ThemeContext";
import { Buyer, CoBuyer } from "./BuyerRegistration";

interface PhoneNumberInputProps {
	buyer?: Buyer | CoBuyer;
	fieldName: string;
	formik: FormikProps<any>;
	isDisabled: boolean;
	invalidCallback?: () => void;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({ buyer, fieldName, formik, invalidCallback, isDisabled }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [showPhoneResults, setShowPhoneResults] = useState(false);
	const [searchBuyerData, setSearchBuyerData] = useState<{ exclusive: number; nonExclusive: number }>({ exclusive: 0, nonExclusive: 0 });
	const { theme, setTheme } = useContext(ThemeContext);
	useEffect(() => {
		console.log("Formik values", formik.values);
	}, [formik.values]);
	useEffect(() => {
		console.log("Value here", formik.values[fieldName]);
		console.log("!buyer", buyer);
		console.log("Checking : ", getIn(formik.values, fieldName));
		if (!buyer && isValidPhoneNumber(getIn(formik.values, fieldName))) {
			console.log("Calling get buyer registrations");
			getBuyerRegistrations(formatPhoneNumber(getIn(formik.values, fieldName)));
		} else if (buyer?.phoneNumber) {
			console.log("Showing results panel");
			setShowPhoneResults(true);
			setSearchBuyerData({ exclusive: buyer?.otherAgentsExclusive || 0, nonExclusive: buyer?.otherAgentsNonExclusive || 0 });
		}
	}, [formik.values[fieldName]]);
	const getBuyerRegistrations = (phoneNumber: string) => {
		setIsLoading(true);
		// Simulate an API call
		setTimeout(() => {
			setShowPhoneResults(true);
			setIsLoading(false);
			if (fieldName == "phoneNumber") {
				setSearchBuyerData({ exclusive: 2, nonExclusive: 1 });
			} else {
				setSearchBuyerData({ exclusive: 0, nonExclusive: 1 });
			}
		}, 1000);
	};
	const backgroundDisabledColor = theme === "dark" ? "bg-gray-800" : theme === "contrast" ? "contrast-background" : disabledBgColor;
	return (
		<div className="relative">
			<label className="block">
				Primary Phone Number <span className="text-red-500">*</span>
			</label>

			<div className="relative">
				<input
					type="tel"
					name={fieldName}
					value={getIn(formik.values, fieldName)}
					onChange={(e) => {
						const { value } = e.target;
						const formattedValue = formatPhoneNumber(value);
						console.log("FOrmatted value", formattedValue);
						formik.setFieldValue(fieldName, formattedValue);
						if (isValidPhoneNumber(formattedValue)) {
							getBuyerRegistrations(formattedValue);
						} else {
							setShowPhoneResults(false);
							if (invalidCallback) {
								invalidCallback();
							}
						}
					}}
					onBlur={formik.handleBlur}
					className={`input-registered-required w-full px-3 py-2 border rounded ${isDisabled ? backgroundDisabledColor : ""}`}
					disabled={isDisabled}
				/>
				{isLoading && (
					<span className="absolute right-2 top-1/2 transform -translate-y-1/2">
						<BeatLoader size={5} color={theme === "light" ? "black" : "white"} />
					</span>
				)}
				{/* {!isLoading && isValidPhoneNumber(formik.values[fieldName]) && <CheckCircleIcon width={20} className="text-green-500 absolute right-2 top-1/2 transform -translate-y-1/2" />} */}
			</div>
			<FormikError formik={formik} field={fieldName} />
			{showPhoneResults && (
				<ul className="list-disc pl-5 space-y-2 mb-4 mt-2">
					<li className={searchBuyerData.exclusive > 0 ? "text-red-500" : ""}>{searchBuyerData.exclusive} Exclusive Agreements</li>
					<li>{searchBuyerData.nonExclusive} Non-Exclusive Agreements</li>
				</ul>
			)}
		</div>
	);
};

export default PhoneNumberInput;
