import React from "react";
import ReactModal from "react-modal";
import { Buyer } from "../BuyerRegistration";

interface CancelRegistrationModalProps {
	fetchData: () => void;
	isModalOpen: boolean;
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	buyerToCancel: Buyer;
}

const CancelRegistrationModal: React.FC<CancelRegistrationModalProps> = ({ isModalOpen, setIsModalOpen, buyerToCancel, fetchData }) => {
	const handleConfirm = () => {
		console.log(`Canceling ${buyerToCancel.name}'s registration`);
		// after canceled is complete
		setIsModalOpen(false);
		fetchData();

		// Add confirmation logic here
	};

	return (
		<ReactModal
			style={{
				overlay: {
					backgroundColor: "transparent",
					inset: "unset",
					zIndex: 10,
				},
			}}
			appElement={document.getElementById("root") ?? undefined}
			isOpen={isModalOpen}
			onRequestClose={() => setIsModalOpen(false)}
			contentLabel="Confirm Cancel"
			shouldCloseOnOverlayClick={true}
			shouldCloseOnEsc={true}
		>
			<div className="buyer-registration-cancel-modal fixed z-10 inset-0 overflow-y-auto article-details display-block" aria-labelledby="modal-title" role="dialog" aria-modal="true">
				<div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block">
					<div className="fixed inset-0 bg-secondary bg-opacity-75 transition-opacity" aria-hidden="true" />

					<div className="inline-block w-full align-bottom bg-primary rounded-lg p-4 text-center overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-2xl sm:p-6" style={{ position: "absolute", top: "45%", left: "50%", transform: "translate(-50%, -50%)" }}>
						<div className="relative inline-block align-bottom overflow-hidden transform transition-all sm:mt-4 sm:align-middle w-full">
							<div className="p-0">
								<div className="sm:flex sm:items-start">
									<div className="mt-3 sm:mt-0 sm:ml-4 w-full">
										<h3 className="text-lg leading-6 font-medium text-red-600 text-capitalize">Cancel Buyer Registration</h3>
										<p className="text-sm body-copy mt-5">
											Are you sure you want to cancel <b>{buyerToCancel?.name}'s</b> registration? They will be removed from your list of registered buyers. <b>You can always re-register them in the future</b>.
										</p>

										<div className="mb-5 mt-7 sm:flex sm:justify-center">
											<button
												type="button"
												className="mt-3 sm:mt-0 w-full sm:w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:text-sm"
												onClick={() => setIsModalOpen(false)}
												style={{ marginBottom: window.innerWidth <= 640 ? "1rem" : "0" }}
											>
												Cancel
											</button>
											<button
												type="button"
												className="w-full sm:w-auto inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:text-sm"
												onClick={handleConfirm}
											>
												Confirm
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ReactModal>
	);
};

export default CancelRegistrationModal;
